import React, { lazy,Suspense } from "react";
import { QueryClient,QueryClientProvider } from "react-query";
import "./App.css"; 
// import { Route, Routes } from "react-router-dom";

const AppRouting = lazy(()=>(import("./AppEntry/AppRouting")));
// const AartiHome = lazy(()=>(import('./ImmortalDharm/OurServices/Aarti/AartiHome')))
const NavBar = lazy(()=>(import("./ImmortalDharm/NavBar/NavBarEntry")));
const FooterEntry = lazy(()=>(import("./ImmortalDharm/Footer/FooterEntry")));
function App() {
  const queryClient = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
      <Suspense fallback={<>...</>}> < NavBar/></Suspense>
      <Suspense fallback={<>...</>}> < AppRouting/></Suspense>
      <Suspense fallback={<>...</>}> < FooterEntry/></Suspense>
      </QueryClientProvider>
    </div>
  );
}

export default App;
